.swal {
  &.swal-modal {
    width: auto;
  }

  .swal-content {
    text-align: left;
    padding: 0;
    margin: 0;
  }
}