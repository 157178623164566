@import "~styles/variables/colors";
@import "~styles/variables/spacing";

$switch--height: 24px;
$switch--width: 42px;
$switch--indicator-scale: 6px;

.switch-input {
  $root: &;

  display: inline-flex;
  align-items: center;

  &--disabled {
    cursor: not-allowed;

    & > * {
      cursor: not-allowed !important;
    }
  }

  &--label-position {
    &--right {
      flex-direction: row-reverse;
    }
  }

  &__label {
    margin-right: $spacing--8;
    color: $color--grey-2;

    #{$root}--label-position--right & {
      margin-right: unset;
      margin-left: $spacing--8;
    }
  }

  &__input {
    $inputRoot: &;

    display: inline-flex;
    align-items: center;
    width: $switch--width;
    border-radius: $switch--height;
    background-color: $color--grey-5;
    border: 1px solid $color--grey-3;
    padding: $spacing--2;
    transition: 200ms background-color ease-in-out;

    #{$root}--disabled & {
      opacity: 0.6;
    }

    &--on {
      background-color: $color--primary;
    }

    &:hover {
      cursor: pointer;
    }

    &__indicator {
      height: #{$switch--height - $switch--indicator-scale};
      width: #{$switch--height - $switch--indicator-scale};
      background-color: $color--white;
      border-radius: 50%;
      border: 1px solid $color--grey-3;
      transition: 200ms transform ease-in-out;

      #{$inputRoot}--on & {
        transform: translateX(#{($switch--width - ($switch--indicator-scale * 2)) - ($switch--height - $switch--indicator-scale) * 0.5 + 1});
      }
    }
  }
}