@import '~styles/variables/colors';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.table-pagination-actions {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &__content {
    margin-left: $spacing--12;
    margin-right: $spacing--12;
    min-width: 72px;
    text-align: center;

    .LoadingIndicator {
      padding: 0;
      font-size: $font-size--m-large;
      color: $color--grey-3;
    }

    &__error {
      color: $color--grey-3;
    }

    &__page-divider {
      display: inline-block;
      padding: $spacing--8;
    }
  }
}