@import "~styles/variables/colors";
@import "~styles/variables/shadows";
@import "~styles/variables/typography";

$toastStatusColorMap: (
  success: $color--primary,
  error: $color--error-shade-2,
  warning: $color--orange
);

.Toastify {
  $root: &;

  &__toast {
    $toastRoot: &;
    box-shadow: $shadow--card;

    @each $toast, $color in $toastStatusColorMap {
      &--#{$toast} {
        background-color: lighten($color, 40%) !important;
  
        #{$toastRoot}-body,
        #{$root}__close-button {
          color: $color;
          font-weight: $font-weight--bold;
        }

        #{$root}__progress-bar {
          background-color: transparentize($color, 0.4);
        }

        #{$root}__toast-icon {
          svg {
            fill: $color;
          }
        }
      }
    }
  }
}