@import '~styles/variables/colors';
@import '~styles/variables/layout';
@import '~styles/variables/spacing';

.actionbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color--grey-4;
  padding: $spacing--16 $spacing--24;
  height: $actionbar--height;
  box-sizing: border-box;
  background-color: $color--main-bg;

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: $spacing--12;
      }
    }
  }
}