@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

$intentColorMap: (
  success: $color--primary,
  error: $color--error-shade-2,
  warning: $color--orange,
  info: $color--blue,
  neutral: $color--grey-2,
);

.hint {
  $root: &;

  display: inline-flex;
  flex-direction: column;

  border-radius: $border-radius--card;
  padding: $spacing--12 $spacing--16;

  @each $intent, $color in $intentColorMap {
    &.hint--#{$intent} {
      background-color: transparentize($color, 0.92);

      #{$root}__title {
        color: $color;
        margin-bottom: $spacing--8;

        .icon {
          margin-right: $spacing--8;
        }
      }
    }
  }

  .text {
    line-height: 1.4;
  }
}