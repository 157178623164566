@import '~styles/variables/colors';
@import '~styles/variables/layout';
@import '~styles/variables/spacing';

.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebar--width;
  padding: $spacing--16;
  box-sizing: border-box;
  height: 100%;
  background-color: transparentize($color--grey-2, 0.95);

  &__top {
    flex: 1;
  }

  &__logo {
    display: flex;
    margin-bottom: $spacing--4;

    img {
      height: 32px;
    }
  }

  &__navigation {
    .sidebar-navigation-item:not(:last-child) {
      margin-bottom: $spacing--24;
    }
  }
}