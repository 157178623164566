.products-table-error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  text-align: center;

  img {
    height: 140px;
  }
}