@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/shadows';
@import '~styles/variables/z-index';

.dropdown-button {  
  z-index: $z-index--dropdown-menu;

  &__menu {
    background-color: $color--white;
    border-radius: $border-radius--card;
    box-shadow: $shadow--card;
    min-width: 175px;
  }
}