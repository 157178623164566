@import '~styles/variables/colors';

.LoadingIndicator {
  padding: var(--input-padding);
  font-size: 2rem;
  color: $color--grey;
  display: flex;
  justify-content: center;
  width: 100%;

  &.fullscreen {
    height: 100vh;
    padding: 0;
    align-items: center;
  }

  &.fullcontent {
    height: calc(100vh - (var(--actionbar-height) * 2 + var(--container-margin) * 2));
    padding: 0;
    align-items: center;
  }

  &.primary {
    color: $color--primary;
  }

  &.large {
    font-size: 3rem;
  }

  &.contained {
    width: unset;
  }
}
