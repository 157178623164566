@import '~styles/variables/colors';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.sidebar-navigation-item {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color--grey-1;
  text-decoration: none;

  // If the item is locked, we want to disable all pointer events
  // and set the color if icon(s) and text to $color--grey-3
  &--locked {
    color: $color--grey-3;
    pointer-events: none;

    #{$root}__left {
      &__title {
        color: $color--grey-3;
      }
    }

    #{$root}__right {
      pointer-events: all;
      cursor: not-allowed;
    }
  }

  // When item is active or we hover over it, if the item is not locked,
  // we want the icon and text color to be $color--primary
  &--active,
  &:hover {
    &:not(#{$root}--locked) {
      color: $color--primary;
  
      #{$root}__left {
        &__title {
          color: $color--primary;
        }
      }
    }
  }

  // When item is active and not locked, we want the text to be bold
  &--active:not(#{$root}--locked) {
    #{$root}__left {
      &__title {
        font-weight: $font-weight--bold;
      }
    }
  }

  .icon {
    font-size: $font-size--l;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    &__icon {
      margin-right: $spacing--8;
    }

    &__title {
      line-height: 1;
    }
  }
}