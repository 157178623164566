@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.post-code-and-name-input {
  display: inline-flex;
  flex-direction: column;

  &--inner {
    display: inline-flex;
  }

  &__label {
    display: inline-block;
    text-transform: uppercase;
    // Not a constant since it's very specific and used
    // only for the label
    font-size: 11px;
    font-weight: $font-weight--bold;
    color: $color--grey-2;
    margin-bottom: $spacing--4;

    .icon {
      margin-right: $spacing--4;
    }
  }

  &__post-code {
    max-width: 80px;
    margin-right: $spacing--8;

    .input__element {
      min-width: 0;
    }
  }

  &__post-name {
    flex: 1;
  }
}