@import "~styles/variables/spacing";

$integerToWordMap: (
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
);

$gapSizeMap: (
  'none': 0,
  'small': $spacing--8,
  'normal': $spacing--16,
  'medium': $spacing--24,
  'large': $spacing--32,
);

.grid {
  display: grid;

  @for $i from 1 through 6 {
    $classSufix: map-get($integerToWordMap, $i);

    &--#{$classSufix} {
      grid-template-columns: repeat($i, 1fr);
    }
  }

  @each $gapName, $gapSize in $gapSizeMap {
    &--gap-#{$gapName} {
      gap: $gapSize;
      padding-bottom: $gapSize;
    }
  }

  &__child {
    display: flex;
    flex: 1;
    width: 100%;

    & > *:not(.input) {
      flex: 1;
    }

    @for $i from 1 through 6 {
      &--span-#{$i} {
        grid-column: span $i;
      }
    }
  }
}