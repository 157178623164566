@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.checkbox-group-input {

  &__label {
    color: $color--grey-2;
    margin-bottom: $spacing--8;
    display: inline-block;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  .checkbox-input {
    margin-bottom: $spacing--16;

    &:not(:last-child) {
      margin-right: $spacing--16;
    }
  }
}