@import '~styles/variables/colors';
@import '~styles/variables/spacing';


.table-actions {
  display: flex;
  align-items: center;

  &__segment {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: $spacing--12;
    }

    & > .input:not(.input--has-focus) .input__element {
      border-color: $color--grey-2;
    }

    & > .select-outer {
      min-width: 240px;
    }

    &:not(:last-child) {
      padding-right: $spacing--16;
      margin-right: $spacing--16;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 10%;
        width: 1px;
        height: 80%;
        background-color: $color--grey-4;
      }
    }
  }
}