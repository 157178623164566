@import '~styles/variables/spacing';

.product-price-tooltip {
  min-width: 225px;

  &__details {
    display: flex;
    flex-direction: column;
    margin-top: $spacing--8;
  }
}