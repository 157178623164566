@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.timeline-labels {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(75px, 1fr) );
  grid-gap: $spacing--16;

  &__label {
    background-color: transparentize($color--black, 0.97);
    border-radius: $border-radius--card;
    flex: 1;
    padding: $spacing--8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}