.card
  background: var(--white)
  padding: 1.429rem
  box-shadow: var(--card-shadow)
  border-radius: var(--default-radius)
  display: flex
  flex-direction: column

  .card-title
    font-size: 0.857rem
    text-transform: uppercase
    color: var(--grey)
    font-weight: 700
    margin-top: 0
    margin-bottom: 1.429rem

  .card-cta
    .cta.dimmed-cta
      color: var(--grey-a50)
      font-size: 0.714rem
      margin-top: 1.429rem