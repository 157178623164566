@import "~styles/helpers/button";

@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/shadows";
@import "~styles/variables/spacing";
@import "~styles/variables/transitions";
@import "~styles/variables/typography";

.button {
  // Save the reference to the root element
  $root: &;

  outline: none;
  border: none;
  appearance: none;
  /**
  * For button size "normal", this yields in
  * padding: 12px 14px;
  */
  padding: 1em 1.166em;
  border-radius: $border-radius--button;
  display: inline-flex;
  font-size: $font-size--sm;
  font-weight: $font-weight--bold;
  line-height: 1;
  transition: $transition--button;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .LoadingIndicator {
      padding: 0;
      font-size: $font-size--sm;
    }
  }

  &--loading {
    // If the button is loading, we want to accept opacity 0 to every element except for
    // the loading overlay
    & > *:not(.button__loading-overlay) {
      opacity: 0;
    }
  }

  &--fullwidth {
    width: 100%;
  }

  // Button Colors
  @include setButtonColors($root);

  &--is-disabled {
    opacity: 0.4;
    pointer-events: none;

    &#{$root}--ghost {
      opacity: 0.55;
    }
  }

  // Button Sizes
  &--extra-small {
    font-size: $font-size--xs;

    & > .LoadingIndicator {
      font-size: $font-size--xs;
    }
  }

  &--small {
    font-size: $font-size--s;

    & > .LoadingIndicator {
      font-size: $font-size--s;
    }
  }

  &--large {
    font-size: $font-size--m;

    & > .LoadingIndicator {
      font-size: $font-size--m;
    }
  }

  // Icon Position
  &--icon-position-left {
    flex-direction: row-reverse;

    // We only want affect direct child of the button
    & > .icon {
      margin-right: $spacing--12;
    }
  }

  &--icon-position-right {
    // We only want affect direct child of the button
    & > .icon {
      margin-left: $spacing--12;
    }
  }

  // Button with only icon (without text)
  &--has-only-icon {
    & > .icon {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // Disabling pointer events
  &--no-pointer-events {
    pointer-events: none !important;
  }

  // General Hover states
  &:hover {
    cursor: pointer;
  }
}