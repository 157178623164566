@import '~styles/variables/colors';
@import '~styles/variables/spacing';

.dropdown-option-wrapper {
  padding: $spacing--12 $spacing--16;

  &:not(:last-child) {
    border-bottom: 1px solid $color--grey-4;
  }

  &:hover {
    cursor: pointer;
    background-color: $color--grey-6;
  }
}