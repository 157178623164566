@import '~styles/variables/colors';


.pageable-table {
  .table {
    &__head {
      border-top: 1px solid $color--grey-4-5;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}