@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

$checkbox--border-width: 2px;

.checkbox-input {
  $root: &;

  display: flex;
  align-items: center;

  &__element {
    // hide the original input field
    display: none;
  }

  &__icon {
    display: inline-flex;
    border: $checkbox--border-width solid $color--grey-2;
    width: #{$spacing--24 - ($checkbox--border-width * 2)};
    height: #{$spacing--24 - ($checkbox--border-width * 2)};
    border-radius: $border-radius--checkbox;
    align-items: center;
    justify-content: center;
    font-size: $font-size--m;

    &:hover {
      cursor: pointer;
    }

    #{$root}--is-checked & {
      background-color: $color--primary;
      color: $color--white;
      border-color: $color--primary;
    }

    #{$root}--is-disabled & {
      border-color: $color--grey-4;
      background-color: transparent;

      &:hover {
        cursor: not-allowed;
      }
    }

    #{$root}--is-disabled#{$root}--is-checked & {
      color: $color--grey-2;
      background-color: $color--grey-4;
      border-color: $color--grey-4;
    }
  }

  &__label {
    margin-left: $spacing--8;
  }
}