@import '~styles/variables/spacing';

.product-status-indicator {
  
  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .status-dot {
      margin-right: $spacing--4;
    }
  }
}