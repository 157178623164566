@import "~styles/variables/colors";
@import "~styles/variables/layout";
@import "~styles/variables/spacing";
@import "~styles/variables/z-index";

.fixed-footer {
  padding: 0 $spacing--24;
  background-color: $color--main-bg;
  height: $fixed-footer-height;
  display: flex;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: $sidebar--width;
  right: 0;
  z-index: $z-index--fixed-footer;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    background: $color--grey-3;
    left: 0%;
    right: 0;
    top: 0;
    width: 100%;
  }

  &--is-contained {
    max-width: $container--width;
    margin: 0 auto;
  }
}