.icon {
  transition: var(--ease-transition);

  &--no-transition {
    transition: none
  }

  &--reversed {
    transform: scaleX(-1)
  }
}