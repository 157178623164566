@import "~styles/variables/layout";
@import "~styles/variables/spacing";

.dashboard-container {
  &--is-contained {
    max-width: $container--width;
    margin: 0 auto;
  }

  &__breadcrumbs {
    margin-bottom: $spacing--16;
  }

  &__title {
    margin-bottom: $spacing--32;
  }
}