.label-font
  font-size: 0.857rem
  color: var(--grey)
  text-transform: uppercase
  font-weight: bold
  text-decoration: none

.centered
  text-align: center

.color-error
  color: var(--error)

.color-warning
  color: var(--warning)

.page-title
  font-size: 2.286rem
  font-weight: 800
  color: var(--grey-shade-2)
  margin-bottom: var(--container-margin)
  &.sticky
    position: sticky
    top: var(--actionbar-height)
    background-color: var(--main-bg)
    margin-top: 0
    padding: 1.428rem 0
    z-index: 9
    margin-bottom: calc(var(--container-margin) - 1.428rem)
  &.no-margin-top
    margin-top: 0

.page-subtitle
  font-size: 1.714rem
  font-weight: 800
  color: var(--grey-shade-2)
  margin-top: 2.5rem
  margin-bottom: 1.071rem
  &.no-margin-top
    margin-top: 0