@import '~styles/variables/spacing';

.product-edit-form {
  margin-top: $spacing--24;

  &__footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}