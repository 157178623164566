@import '~styles/variables/colors';

.table-body-empty-state-base {
  height: 700px;
  max-height: calc(100vh - 140px);
  background-color: $color--white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $color--grey-4-5;
}