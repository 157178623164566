@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.timeline-add-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $color--grey-3;
  border-radius: $border-radius--card;
  transition: border 200ms ease-in-out;

  .button {
    color: $color--grey-3 !important;
    width: 100%;
    padding: $spacing--24 $spacing--8;
  }

  &:hover {
    cursor: pointer;
    border-color: $color--grey-2;

    .button {
      color: $color--grey-2 !important;
    }
  }
}