@import '~styles/variables/spacing';


.table-loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;

  .LoadingIndicator {
    padding: 0;
    padding-bottom: $spacing--12;
  }
}