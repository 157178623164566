$container--width: 1440px;
$fixed-footer-height: 75px;
$sidebar--width: 256px;
$actionbar--height: 65px;
  
@mixin setupLayoutVariables() {
  --cta-padding: 0.75em 1.75em;
  --main-cta-padding: 1.071rem 1.429rem;
  --input-padding: 1.143em;
  --default-radius: 0.357rem;
  --actionbar-height: 68px;
  --sidebar-width: 75px;
  --container-margin: 45px;
  --default-input-height: 1.714rem;
  --default-input-width: 3.143rem;
  --default-input-indicator: 1.286rem;
  --default-input-indicator-padding: 0.2rem;
}