@import '~styles/variables/colors';
@import '~styles/variables/border-radius';
@import '~styles/variables/shadows';
@import '~styles/variables/spacing';

$card--image-height: 165px;

.merchant-card {
  background-color: $color--white;
  border-radius: $border-radius--card;
  box-shadow: $shadow--card;
  overflow: hidden;

  &__image {
    height: $card--image-height;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: $spacing--16 $spacing--16 0;
    display: flex;
    flex-direction: column;

    .text:first-child {
      margin-bottom: $spacing--4;
    }
  }

  &__footer {
    display: flex;
    padding: $spacing--16;

    .button {
      flex: 1;
    }
  }
}