@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.sidebar-user-menu {
  border-radius: 100px;
  display: flex;
  padding-right: $spacing--16;

  .tooltip {
    display: flex !important;
    align-items: center;
    flex: 1;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    .icon {
      margin-left: $spacing--16;
    }
  }

  &:hover {
    background-color: transparentize($color--black, 0.95);
    cursor: pointer;
  }
}