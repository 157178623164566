@import "~styles/helpers/image";

@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

$imageDimensions: 105px;

.settings-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparentize($color--black, 0.95);
  padding: $spacing--24;
  position: relative;
  border-radius: $border-radius--card;
  overflow: hidden;

  &__image {
    width: $imageDimensions;
    height: $imageDimensions;
    margin-bottom: $spacing--16;

    @include coverImageToFitParent();
  }

  &__title {
    margin-bottom: $spacing--8;
  }

  &__details {
    text-align: center;
  }

  .button {
    margin-top: $spacing--16;
    min-width: 50%;
  }

  &__disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize($color--black, 0.75);
    padding: $spacing--16;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}