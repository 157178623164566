@import "~styles/variables/colors";
@import "~styles/variables/typography";

.text {
  line-height: 1.2;
  
  // Font sizes
  &--size-extra-small {
    font-size: $font-size--xs;
  }

  &--size-smaller {
    font-size: $font-size--s;
  }

  &--size-small {
    font-size: $font-size--sm;
  }

  &--size-normal {
    font-size: $font-size--m;
  }

  &--size-normal-large {
    font-size: $font-size--m-large;
  }

  &--size-large {
    font-size: $font-size--l;
  }

  &--size-larger {
    font-size: $font-size--xl;
  }

  &--size-extra-large {
    font-size: $font-size--xxl;
  }

  // Font weights
  &--weight-light {
    font-weight: 300;
  }
  &--weight-regular {
    font-weight: 400;
  }
  &--weight-bold {
    font-weight: 700;
  }
  &--weight-extra-bold {
    font-weight: 800;
  }

  // Line heights
  &--line-height-none {
    line-height: 1;
  }
  &--line-height-normal {
    line-height: 1.2;
  }
  &--line-height-medium {
    line-height: 1.4;
  }
  &--line-height-large {
    line-height: 1.68;
  }

  // Colors
  &--color-grey {
    color: $color--grey;
  }

  &--color-grey-light {
    color: $color--grey-2;
  }

  &--color-black {
    color: $color--black;
  }

  &--color-primary {
    color: $color--primary;
  }

  &--color-white {
    color: $color--white;
  }

  &--color-error {
    color: $color--error;
  }

  // Truncate
  &--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    // We want to support truncating text to up to
    // 5 lines. Anything beyond that should have a different
    // solution.
    @for $i from 1 through 5 {
      &--#{$i} {
        -webkit-line-clamp: #{$i};
      }
    }
  }
}