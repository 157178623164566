@import "~styles/variables/spacing";

$margin-list: 'top', 'bottom', 'left', 'right';

$spacing-map: (
  '2': $spacing--2,
  '4': $spacing--4,
  '8': $spacing--8,
  '12': $spacing--12,
  '16': $spacing--16,
  '24': $spacing--24,
  '32': $spacing--32,
);

@each $margin in $margin-list {
  @each $spacing, $spacingValue in $spacing-map {
    .margin-#{$margin}--#{$spacing} {
      @if $margin == 'top' {
        margin-top: $spacingValue;
      }

      @if $margin == 'bottom' {
        margin-bottom: $spacingValue;
      }

      @if $margin == 'left' {
        margin-left: $spacingValue;
      }

      @if $margin == 'right' {
        margin-right: $spacingValue;
      }
    }
  }
}