@import "~styles/variables/spacing";

.section {
  padding-bottom: $spacing--16;
  padding-top: $spacing--16;

  &--is-first {
    padding-top: 0;
  }

  &__title {
    margin-bottom: $spacing--16;
  }
}