@import '~styles/variables/layout';

.app-layout {
  $root: &;
  min-height: 100vh;

  &--has-fixed-footer {
    #{$root}__main-content {
      padding-bottom: 95px;
    }
  }

  &__sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &__main-content {
    padding-left: $sidebar--width;
  }
}