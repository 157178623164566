@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';


.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparentize($color--white, 0.25);
  border-top: 1px solid $color--grey-4-5;
  border-bottom-left-radius: $border-radius--card;
  border-bottom-right-radius: $border-radius--card;
  padding: $spacing--12 $spacing--8;
}