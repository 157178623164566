@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.table {
  $root: &;

  display: block;
  max-width: 100%;

  &__table {
    width: 100%;
    border-spacing: 0;
  }

  &__head {
    display: flex;
    border-top-left-radius: $border-radius--card;
    border-top-right-radius: $border-radius--card;
    border-bottom: 1px solid $color--grey-4-5;
    background-color: transparentize($color--white, 0.25);
    font-size: 11px;
    font-weight: $font-weight--bold;
    color: $color--grey-2;
  }

  &__row {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $color--grey-4-5;
    }

    &--clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &--body {
      background-color: $color--white;

      &:hover {
        background-color: darken($color--white, 2%);
      }

      &:last-child {
        border-bottom-left-radius: $border-radius--card;
        border-bottom-right-radius: $border-radius--card;
      }
    }
  }

  &__cell {
    display: inline-flex;
    align-items: center;
    font-size: $font-size--m;
    color: $color--grey-1;
    position: relative;
    box-sizing: border-box;

    #{$root}__head & {
      display: inline-flex;
      padding-top: $spacing--8;
      padding-bottom: $spacing--8;

      span {
        font-size: 11px;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $color--grey-4-5;
    }

    &--padding-normal {
      padding: $spacing--8;
    }

    &--padding-medium {
      padding: $spacing--12;
    }

    &--padding-large {
      padding: $spacing--16;
    }

    &--align-left {
      text-align: left;
      justify-content: flex-start;

      &--important {
        text-align: left !important;
        justify-content: flex-start !important;
      }
    }

    &--align-center {
      text-align: center;
      justify-content: center;

      &--important {
        text-align: center !important;
        justify-content: center !important;
      }
    }

    &--align-right {
      text-align: right;
      justify-content: flex-end;

      &--important {
        text-align: right !important;
        justify-content: flex-end !important;
      }
    }

    @for $i from 1 through 20 {
      &--size-#{$i} {
        width: #{(100 * 0.05) * $i}#{'%'};

        img {
          height: #{(100 * 0.25) * $i}px;
        }
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      border-radius: $border-radius--card;
    }
  }
}