@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.data-entry {
  $root: &;

  display: inline-flex !important;
  flex-direction: column;

  &--fullwidth {
    width: 100%;
  }

  &__label {
    color: $color--grey-2;
    font-size: $font-size--label;
    font-weight: $font-weight--bold;
    padding-bottom: $spacing--4;
  }

  &__value {
    #{$root}--has-label & {
      padding-top: $spacing--4;
    }

    display: inline-flex;
    justify-content: space-between;
  }

  &__icon {
    padding-left: $spacing--16;
  }
}