@import "~styles/variables/border-radius";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.sidebar-change-merchant-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .avatar {
    margin-bottom: $spacing--12;  

    .avatar__image {
      border-radius: $border-radius--8;
    }

    .avatar__name {
      font-weight: $font-weight--bold;
    }
  }

  .button {
    width: 100%;
  }
}