@import "~styles/variables/spacing";

.sidebar-user-menu-content {
  width: 228px;
  padding: $spacing--8 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:not(:last-child) {
    padding-bottom: $spacing--16;
  }

  .icon {
    margin-right: $spacing--12;
  }
}