@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';


.table-actionbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color--white;
  border-top-left-radius: $border-radius--card;
  border-top-right-radius: $border-radius--card;
  padding: $spacing--12 $spacing--8;
}