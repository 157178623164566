@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.user-menu-dropdown {
  border-radius: 100px;
  display: inline-flex;
  padding-right: $spacing--16;

  .tooltip {
    display: inline-flex !important;
    align-items: center;
  }

  &__content {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-left: $spacing--16;
    }
  }

  &:hover {
    background-color: transparentize($color--black, 0.95);
    cursor: pointer;
  }
}