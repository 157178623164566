$font-size--xs: 8px;
$font-size--s: 10px;
$font-size--label: 11px;
$font-size--sm: 12px;
$font-size--m: 14px;
$font-size--m-large: 16px;
$font-size--l: 18px;
$font-size--xl: 24px;
$font-size--xxl: 32px;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--bold: 700;
$font-weight--black: 900;