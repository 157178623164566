@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.delivery-timeline-entry {
  display: flex;
  flex-direction: column;
  padding: $spacing--8;
  background-color: $color--white;
  border: 1px solid $color--grey-4;
  border-radius: $border-radius--card;
  position: relative;

  &__options {
    position: absolute;
    top: $spacing--4;
    right: 0;
    padding: $spacing--4;
    color: $color--grey-2;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 300ms ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: transparentize($color--black, 0.93);
    }
  }

  &__title {
    margin-bottom: $spacing--6;
  }

  &__postcodes {
    margin-bottom: $spacing--8;
    color: $color--grey-2;
  }
}