@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.tag-element.tag {
  align-items: center;
  background-color: $color--primary;
  border-radius: $border-radius--button;
  color: $color--white;
  display: inline-flex;
  font-size: $font-size--sm;
  font-weight: $font-weight--bold;
  padding: 1em 1.166em;
  border: none;

   // Icon Position
   &--icon-position-left {
    // We only want affect direct child of the tag
    & > .icon {
      margin-right: $spacing--12;
    }
  }

  &--icon-position-right {
    flex-direction: row-reverse;

    // We only want affect direct child of the tag
    & > .icon {
      margin-left: $spacing--12;
    }
  }
}