@import "~styles/variables/icons";

@mixin addPseudoIcon($icon, $position: before) {
  &::#{$position} {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: map-get($pseudoIconsMap, $icon) !important;
  }
}