@import '~styles/constants/intent';
@import '~styles/helpers/colors';

$dot-size: 10px;

.status-dot {
  width: $dot-size;
  height: $dot-size;
  border-radius: #{$dot-size * 0.5};

  @each $intent in $intents {
    &--intent-#{$intent} {
      background-color: getColorForIntent($intent);
    }
  }
}