@use "sass:map";

@import "~styles/variables/colors";

$types: 'primary', 'ghost', 'link', 'outlined';
$intents: 'success', 'info', 'error', 'warning', 'neutral';

@function getCombinedClassName($buttonClassName, $type, $intent) {
  // This yields in .button--$type
  $typeClassName: #{$buttonClassName}--#{$type};

  // This yields in .button--$intent
  $intentClassName: #{$buttonClassName}--#{$intent};

  // This yields in &.button--$type.button--$intent which is the classname
  // where we want to apply colors
  $combinedClassName: '&#{$typeClassName}#{$intentClassName}';

  @return $combinedClassName;
}

/**
 * Returns correct background color for primary button type
 * based on passed intent.
 */
@function getPrimaryButtonBackgroundColor($intent) {
  $color: $color--grey;

  @if $intent == 'success' {
    $color: $color--primary;
  } @else if $intent == 'warning' {
    $color: $color--orange;
  } @else if $intent == 'error' {
    $color: $color--error-shade-2;
  } @else if $intent == 'neutral' {
    $color: $color--grey-2;
  }

  @return $color;
}

/**
 * Sets CSS color related rules for primary button type based
 * on passed intent.
 */
@mixin setPrimaryButtonColors($intent, $buttonClassname) {
  $bgColor: getPrimaryButtonBackgroundColor($intent);

  background-color: $bgColor;
  color: $color--white;

  .LoadingIndicator {
    color: $color--white;
  }

  &:hover {
    background-color: darken($bgColor, 5%);
  }
}

/**
 * Sets CSS color related rules for ghost button type based
 * on passed intent.
 */
@mixin setGhostButtonColors($intent) {
  $buttonColor: getPrimaryButtonBackgroundColor($intent);

  $opacityOnHover: 0.8;

  background-color: transparentize($buttonColor, 0.9);
  color: $buttonColor;

  .LoadingIndicator {
    color: $buttonColor;
  }

  &:hover {
    background-color: transparentize($buttonColor, $opacityOnHover);
  }
}

/**
 * Sets CSS color related rules for link button type based
 * on passed intent.
 */
@mixin setLinkButtonColors($intent) {
  $color: getPrimaryButtonBackgroundColor($intent);

  background-color: transparent;
  color: $color;

  .LoadingIndicator {
    color: color;
  }

  &:hover {
    color: darken($color, 10%);
  }
}

/**
 * Sets CSS color related rules for link button type based
 * on passed intent.
 */
@mixin setOutlinedButtonColors($intent) {
  $color: getPrimaryButtonBackgroundColor($intent);

  background-color: transparent;
  color: $color;
  border: 1px solid $color;

  .LoadingIndicator {
    color: color;
  }

  &:hover {
    color: darken($color, 10%);
    border-color: darken($color, 10%);
    background-color: transparentize($color, 0.95);
  }
}

/**
 * Sets CSS color related rules for given button type
 */
@mixin setButtonColorsForType($type, $buttonClassName) {
  @each $intent in $intents {
    #{getCombinedClassName($buttonClassName, $type, $intent)} {
      @if $type == 'primary' {
        // Primary button
        @include setPrimaryButtonColors($intent, $buttonClassName);
      }  @else if $type == 'ghost' {
        // Ghost button
        @include setGhostButtonColors($intent);
      } @else if $type == 'link' {
        // Link button
        @include setLinkButtonColors($intent);
      } @else if $type == 'outlined' {
        // Link button
        @include setOutlinedButtonColors($intent);
      }
    }
  }
}

/**
 * Sets CSS color related rules for each button type
 */
@mixin setButtonColors($buttonClassName) {
  @each $type in $types {
    @include setButtonColorsForType($type, $buttonClassName);
  }
}