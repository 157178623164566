@import "~styles/variables/spacing";

.order-details-footer {
  flex: 1;
  display: flex;
  justify-content: space-between;

  &__item {
    &:not(:last-child) {
      margin-right: $spacing--16;
    }
  }
}