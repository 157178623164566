@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.timeline-column {
  min-width: 100px;
  background-color: transparentize($color--black, 0.97);
  border-radius: $border-radius--card;
  flex: 1;
  padding: $spacing--8;
  transition: background-color 200ms ease-in-out;

  &--dragging-over {
    background-color: transparentize($color--black, 0.93);
  }
}