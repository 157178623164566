@import "~styles/variables/border-radius";
@import "~styles/variables/colors";

.inline-actions {
  display: inline-flex;
  border: 1px solid $color--grey-4;
  border-radius: $border-radius--button;
  background-color: $color--white;

  & > *:not(:last-child) {
    border-right: 1px solid $color--grey-4;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}