@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

$indicator--size: 18px;
$indicator--dot-size: 8px;

.radio-select {
  $root: &;
  display: flex;
  flex-direction: column;

  &__label {
    color: $color--grey-2;
    margin-bottom: $spacing--8;
    font-size: $font-size--sm;
  }

  &__inputs {
    display: flex;

    &--direction-vertical {
      flex-direction: column;
    }
  }

  &__inner {
    $rootInner: &;
    display: inline-flex;
    align-items: center;

    &:hover {
      cursor: pointer;

      & > * {
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      margin-right: $spacing--16;

      #{$root}__inputs--direction-vertical & {
        margin-bottom: $spacing--12;
      }
    }

    &__input {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked ~ #{$rootInner}__indicator {
        background-color: $color--primary;

        &::after {
          background-color: $color--white;
        }
      }
    }

    &__indicator {
      width: $indicator--size;
      height: $indicator--size;
      border-radius: 50%;
      background-color: $color--grey-5;
      border: 1px solid $color--grey-3;
      margin-right: $spacing--8;
      position: relative;
      transition: 200ms background-color ease-in-out;

      &::after {
        content: '';
        width: $indicator--dot-size;
        height: $indicator--dot-size;
        background-color: transparent;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: 200ms background-color ease-in-out;
      }
    }

    &__label {
      color: $color--grey-2;
    }
  }
}