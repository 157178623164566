@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/shadows";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.alert-wrapper {
  $root: &;

  position: relative;
  padding: $spacing--24 $spacing--24 $spacing--16;
  border-radius: $border-radius--button;
  box-shadow: $shadow--card;

  &__close-button {
    position: absolute;
    top: $spacing--16;
    right: $spacing--16;
    color: $color--grey-2;

    &:hover {
      cursor: pointer;
      color: $color--grey-1;
    }
  }

  &__title {
    padding-right: $spacing--16;
    .icon {
      padding-right: $spacing--8;

      #{$root}--error & {
        color: $color--error-shade-2;
      }

      #{$root}--success & {
        color: $color--primary;
      }

      #{$root}--warning & {
        color: $color--orange;
      }
    }
  }

  &__content {
    padding: $spacing--24 0;
    font-size: $font-size--sm;
    display: flex;
    flex-direction: column;

    .text {
      line-height: 1.618;
    }

    a {
      color: $color--grey-1 !important;
      font-weight: $font-weight--bold;
    }
  }

  &__footer {
    padding-top: $spacing--16;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + #{$spacing--24 * 2});
      left: -$spacing--24;
      top: 0;
      height: 1px;
      display: block;
      background-color: $color--grey-4;
    }
  }
}