@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

.input {
  $root: &;

  display: inline-flex;
  flex-direction: column;

  &--inner {
    position: relative;
    display: flex;
  }

  &--is-fullwidth {
    width: 100%;

    #{$root}__element {
      min-width: unset;
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__element {
    border: 1px solid $color--grey-3;
    outline: none;
    padding: $spacing--16;
    border-radius: $border-radius--card;
    color: $color--grey-1;
    background-color: $color--grey-5;
    min-width: 200px;
    flex: 1;

    &::placeholder {
      color: $color--grey-3;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // Hack to hide Chrome blue background on autofill
      box-shadow: 0 0 0 1000px $color--grey-5 inset !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: textfield;
      text-align: right;
    }
  }

  &__label {
    text-transform: uppercase;
    // Not a constant since it's very specific and used
    // only for the label
    font-size: 11px;
    font-weight: $font-weight--bold;
    color: $color--grey-2;
    margin-bottom: $spacing--4;

    .icon {
      margin-right: $spacing--4;
    }
  }

  &__icon {
    color: $color--grey-2;
    position: absolute;
    right: $spacing--16;
    top: 50%;
    transform: translateY(-50%);

    &:nth-child(3) {
      right: $spacing--16 * 2 + $spacing--8;
    }

    &--clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &--copy {
      &:hover {
        color: $color--primary !important;
      }
    }
  }

  // Flip the icon position if IconPosition.LEFT
  &--icon-position {
    &--left {
      #{$root}--inner {
        // The input element has 16px padding on its own + the icon has 16px inset
        // + we want additional 8px of spacing between the icon and the input
        #{$root}__element {
          padding-left: $spacing--16 * 2 + $spacing--8;
        }
      }

      #{$root}__icon {
        right: unset;
        left: $spacing--16;
      }
    }
  }

  &__error {
    border: 1px solid $color--error;
    padding: $spacing--8 $spacing--16;
    font-size: $font-size--sm;
    background-color: transparentize($color--error, 0.9);
    border-bottom-left-radius: $border-radius--card;
    border-bottom-right-radius: $border-radius--card;
    border-top-color: transparent;
  }

  // When input has focus
  &--has-focus {
    #{$root}__label,
    #{$root}__icon {
      color: $color--primary;
    }

    #{$root}__element {
      border-color: $color--primary;
    }
  }

  &--has-error {
    #{$root}__label,
    #{$root}__icon {
      color: $color--error;
    }

    #{$root}__element {
      border-color: $color--error;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // When input is disabled
  &--is-disabled {
    #{$root}__icon {
      color: $color--grey-2;
    }

    #{$root}__element {
      background-color: $color--grey-4;
      color: $color--grey-2;
    }
  }

  // When input should be transparent
  &--is-transparent & {
    &__element {
      background-color: transparent;
    }
  }

  // Input type number
  &--type-number & {
    &__element {
      text-align: right;
    }
  }

  // Size: small
  &--size-small & {
    &__element {
      padding: $spacing--12;
    }

    &__icon {
      right: $spacing--12;
    }
  }

  // Size: extra-small
  &--size-extra-small & {
    &__element {
      padding: $spacing--8;
      font-size: $font-size--sm;
    }

    &__icon {
      right: $spacing--8;
    }
  }

  // Compact input overrides
  &--is-compact & {
    &__element {
      border-color: transparent;
    }

    &__error {
      border-color: transparent;
      margin-top: $spacing--4;
      background-color: transparentize($color--error, 0.92);
      border-radius: $border-radius--card;
    }
  }
}