@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';

.collapsible-card {
  border: 1px solid $color--grey-4;
  border-radius: $border-radius--8;
  background-color: $color--white;

  &__header,
  &__content {
    &--inner {
      padding: $spacing--16;
    }
  }

  &__header {
    &--inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__content {
    border-top: 1px solid $color--grey-4;
  }
}