@import '~styles/variables/colors';
@import '~styles/variables/typography';

.price-with-details-cell {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .icon {
    color: $color--grey-2;
    font-size: $font-size--sm;
  }
}