@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.select-control {
  &__control {
    &__icon {
      padding-left: $spacing--8;
      color: $color--grey-2;

      &--has-value {
        color: $color--grey-1;
      }

      &--has-error {
        color: $color--error;
      }
    }
  }

  &__error-container {
    border: 1px solid $color--error;
    border-top-color: transparent;
    border-bottom-left-radius: $border-radius--button;
    border-bottom-right-radius: $border-radius--button;
    background-color: transparentize($color--error, 0.9);
    padding: $spacing--8 $spacing--16;
    font-size: $font-size--sm;
  }
}