@import '~styles/variables/colors';

.actionbar-breadcrumb {
  text-decoration: none;

  &--disabled {
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color--grey-1;
  }

  &:active {
    text-decoration-color: $color--grey-1;
  }
}