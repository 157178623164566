@import "~styles/variables/colors";

.order-details-footer-right {
  $root: &;
  position: relative;
  min-width: 350px;
  display: flex;
  justify-content: flex-end;

  &__loading-indicator {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $color--main-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease-in-out all;
    z-index: 400;

    .LoadingIndicator {
      padding: 0;
      font-size: 1.75rem;
    }
  }

  &--is-loading {
    #{$root}__loading-indicator {
      visibility: visible;
      opacity: 1;
    }
  }
}