@import "~styles/variables/colors";
@import "~styles/variables/spacing";

.actionbar-breadcrumbs {
  display: inline-flex;
  align-items: center;

  & > *:not(:last-child) {
    padding-right: $spacing--8;
  }

  & > .icon {
    color: $color--grey-2;
  }
}