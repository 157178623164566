@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";
@import "~styles/variables/url-encoded-icons";

@import "~styles/helpers/icons";

$checkbox--width: 18px;

.tree-select-input {

  input[type=checkbox] {
    width: $checkbox--width;
    height: $checkbox--width;
    border: 2px solid $color--grey-2;
    border-radius: $border-radius--checkbox;
    margin-right: $spacing--8;

    &:hover {
      cursor: pointer;
    }

    &:checked {
      border-color: $color--primary;
      background-color: $color--primary;
      background-image: url($tick--white);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__label {
    display: inline-block;
    color: $color--grey-2;
    margin-bottom: $spacing--8;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-trigger {
    width: calc(100% - #{$spacing--12});
    border-radius: $border-radius--card;
    padding: 0 !important;
    padding-left: $spacing--12 !important;
    padding-top: $spacing--12 !important;
    color: $color--grey-2;

    &.top {
      @include addPseudoIcon('CHEVRON_UP', after);
    }

    &.bottom {
      @include addPseudoIcon('CHEVRON_DOWN', after);
    }

    &::after {
      position: absolute;
      top: 50%;
      right: $spacing--12;
      transform: translateY(-50%);
    }

    .tag-list {
      .tag-item {
        margin: $spacing--12;
        margin-left: 0;
        margin-top: 0;

        &:last-child:not(:nth-child(1)) {
          display: none;
        }

        .tag {
          font-size: 10px;
          font-weight: $font-weight--bold;
          color: $color--primary;
          background-color: transparentize($color--primary, 0.9);
          border: none;
          border-radius: $border-radius--button;
          padding: $spacing--4 $spacing--12;

          .tag-remove {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: transparent;
            transform: translateY(-1px);

            @include addPseudoIcon('CLOSE', after);

            &::after {
              color: $color--primary;
              font-size: $font-size--sm;
            }
          }
        }
      }
    }
  }

  .dropdown-content {
    min-width: 385px;
    padding: $spacing--8 !important;

    .search {
      padding: $spacing--16;
      background-color: transparentize($color--grey-5, 0.5);
      max-width: 100%;
      box-sizing: border-box;
      border-radius: $border-radius--card;
      border-bottom-color: transparent !important;
      margin-bottom: $spacing--12;
    }

    .node {
      margin-bottom: $spacing--12;
      padding: 0;
      display: flex;
      align-items: center;

      &.tree {
        .toggle {
          width: $checkbox--width;
          display: flex;
          align-items: center;
          justify-content: center;

          &.collapsed {
            @include addPseudoIcon('CARET_RIGHT', after);
          }

          &.expanded {
            @include addPseudoIcon('CARET_DOWN', after);
          }
        }
      }
    }

    .select-all {
      .toggle {
        display: none;
      }
    }
  }
}