@import "~styles/variables/spacing";

$gapSizeMap: (
  'none': 0,
  'small': $spacing--8,
  'normal': $spacing--16,
  'medium': $spacing--24,
  'large': $spacing--32,
);

.column {
  display: flex;
  flex-direction: column;

  @each $gapName, $gapSize in $gapSizeMap {
    &--gap-#{$gapName} {
      & > *:not(:last-child) {
        margin-bottom: $gapSize;
      }
    }
  }
}