@import '~styles/helpers/image';
@import '~styles/variables/colors';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.avatar {
  $root: &;

  display: inline-flex;
  align-items: center;

  &__image {
    width: $spacing--32;
    height: $spacing--32;
    border-radius: 50%;
    overflow: hidden;

    @include coverImageToFitParent();
  }

  &__initials {
    width: $spacing--32;
    height: $spacing--32;
    background-color: $color--primary;
    color: $color--white;
    font-weight: $font-weight--black;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
  }

  &__name {
    padding-left: $spacing--12;
  }

  // Sizes
  &--small {
    #{$root}__image,
    #{$root}__initials {
      width: $spacing--24;
      height: $spacing--24;
    }

    #{$root}__name,
    #{$root}__initials {
      font-size: $font-size--sm;
    }
  }

  &--large {
    #{$root}__image,
    #{$root}__initials {
      width: $spacing--48;
      height: $spacing--48;
    }

    #{$root}__name,
    #{$root}__initials {
      font-size: $font-size--l;
    }
  }
}