$shadow--light: 0 0 4px -2px rgba(0, 0, 0, 0.5);
$shadow--strong: 0 7px 14px -4px rgba(0, 0, 0, 0.22);
$shadow--card: 0px 12px 24px rgba(0, 0, 0, 0.05);
$shadow--light-card: 0 5px 9px -5px var(--black-a10);
$shadow--modal: 0 5px 10px -3px rgba(0, 0, 0, 0.5);

  
@mixin setupShadowVariables() {
  --light-shadow: $shadow--light;
  --strong-shadow: $shadow--strong;
  --card-shadow: $shadow--card;
  --light-card-shadow: $shadow--light-card;
  --modal-shadow: $shadow--modal;
}