$color--primary: #68985c;
$color--primary-a40: rgba(104, 152, 92, 0.4);
$color--primary-a30: rgba(104, 152, 92, 0.3);
$color--primary-a6: rgba(104, 152, 92, 0.06);
$color--primary-dark: #5e8a53;
$color--secondary: #b19d59;
$color--white: #ffffff;
$color--white-a50: rgba(255,255,255,0.5);
$color--white-a30: rgba(255,255,255,0.3);
$color--white-a10: rgba(255,255,255,0.1);
$color--white-shade-2: #fbfbfb;
$color--white-shade-4: #e5e3df;
$color--white-shade-3: #fbf4e0;
$color--white-special-1: #f6f9f6;
$color--white-special-2: #f3f4ec;
$color--main-bg: #fbf9f4;
$color--grey: #5c5c5c;
$color--grey-1: $color--grey;
$color--grey-2: #828282;
$color--grey-3: #BDBDBD;
$color--grey-4: #E0E0E0;
$color--grey-4-5: lighten($color--grey-4, 5%);
$color--grey-5: #F2F2F2;
$color--grey-6: #FAFAFA;
$color--grey-shade-2: #242424;
$color--grey-shade-3: #262626;
$color--grey-a50: rgba(92, 92, 92, 0.5);
$color--grey-a80: rgba(92, 92, 92, 0.8);
$color--black-a65: rgba(38, 38, 38, 0.65);
$color--black-a50: rgba(38, 38, 38, 0.5);
$color--black-a30: rgba(38, 38, 38, 0.3);
$color--black-a15: rgba(38, 38, 38, 0.15);
$color--black-a10: rgba(38, 38, 38, 0.1);
$color--black-a5: rgba(38, 38, 38, 0.05);
$color--black: #222222;
$color--success: #00aa00;
$color--success-a50: rgba(0, 170, 0, 0.5);
$color--success-pale: #ebf4e3;
$color--warning: #ffcc00;
$color--warning-shade-2: #d9ad00;
$color--orange: #FFAB00;
$color--blue: #0065FF;
$color--error: #ff3b30;
$color--error-a5: rgba(255, 59, 48, 0.05);
$color--error-shade-2: #e6352b;
$color--error-shade-3: #ee0033;
$color--error-shade-3-a50: rgba(238, 0, 51, 0.5);

:export {
  colorPrimary: $color--primary;
  colorGrey1: $color--grey-1;
}
  
@mixin setupColorVariables() {
  --primary: #68985c;
  --primary-a40: rgba(104, 152, 92, 0.4);
  --primary-a30: rgba(104, 152, 92, 0.3);
  --primary-a6: rgba(104, 152, 92, 0.06);
  --primary-dark: #5e8a53;
  --secondary: #b19d59;
  --white: #ffffff;
  --white-a50: rgba(255,255,255,0.5);
  --white-a30: rgba(255,255,255,0.3);
  --white-a10: rgba(255,255,255,0.1);
  --white-shade-2: #fbfbfb;
  --white-shade-4: #e5e3df;
  --white-shade-3: #fbf4e0;
  --white-special-1: #f6f9f6;
  --white-special-2: #f3f4ec;
  --main-bg: #fbf9f4;
  --grey: #5c5c5c;
  --grey-shade-2: #242424;
  --grey-shade-3: #262626;
  --grey-a50: rgba(92, 92, 92, 0.5);
  --grey-a80: rgba(92, 92, 92, 0.8);
  --black-a65: rgba(38, 38, 38, 0.65);
  --black-a50: rgba(38, 38, 38, 0.5);
  --black-a30: rgba(38, 38, 38, 0.3);
  --black-a15: rgba(38, 38, 38, 0.15);
  --black-a10: rgba(38, 38, 38, 0.1);
  --black-a5: rgba(38, 38, 38, 0.05);
  --black: #222222;
  --success: #00aa00;
  --success-a50: rgba(0, 170, 0, 0.5);
  --success-pale: #ebf4e3;
  --warning: #ffcc00;
  --warning-shade-2: #d9ad00;
  --error: #ff3b30;
  --error-a5: rgba(255, 59, 48, 0.05);
  --error-shade-2: #e6352b;
  --error-shade-3: #ee0033;
  --error-shade-3-a50: rgba(238, 0, 51, 0.5);
}