@import '~styles/variables/colors';
@import '~styles/variables/spacing';

.sidebar-group {
  padding: $spacing--24 0;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    // Sidebar component has padding set to $spacing--16 and we want the separator line
    // to touch the end of the sidebar, therefore we gave it a negative value of sidebar
    // padding.
    left: -$spacing--16;
    right: -$spacing--16;
    height: 1px;
    background-color: $color--grey-4;
  }
}