@import "~styles/helpers/button";

.dropdown-option {
  display: flex;

  @each $intent in $intents {
    &--#{$intent} {
      $color: getPrimaryButtonBackgroundColor($intent);

      span {
        color: $color !important;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}