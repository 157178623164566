@import 'variables/colors'
@import 'variables/fonts'
@import 'variables/shadows'
@import 'variables/layout'
@import 'variables/transitions'

@import 'classes/display'
@import 'classes/spacing'

@import 'helpers/spacer'
@import 'helpers/typography'
@import 'helpers/transitions'
@import 'helpers/layout'
@import 'helpers/card'

@import 'globals/swal'
@import 'globals/toastify'

@import "normalize"

\:root
	+setupColorVariables
	+setupFontFamilies
	+setupShadowVariables
	+setupLayoutVariables
	+setupTransitionVariables

html
	font-size: var(--base-font-size)

button, input, textarea
	appearance: none

input[readonly]
	&:hover
		cursor: default


body
	background-color: var(--main-bg)
	margin: 0
	padding: 0
	font-family: var(--main-font)
	font-weight: 400
	font-size: var(--base-font-size)
	color: var(--grey-shade-2)
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

	&.has-fixed-footer
		padding-bottom: #{$fixed-footer-height + 20px}

	&.modal-open
		overflow: hidden
