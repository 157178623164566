$z-index--fixed-footer: 90;
$z-index--purchase-orders-labels: 95;
$z-index--action-bar: 100;

$z-index--dropdown-menu: 105;

$z-index--sidesheet: 200;
$z-index--sidesheet__backdrop: 210;
$z-index--sidesheet__content: 220;

$z-index--full-scren-modal: 300;
$z-index--full-scren-modal__header: 301;