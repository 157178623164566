@import '~styles/variables/colors';
@import "~styles/variables/layout";
@import '~styles/variables/spacing';
@import '~styles/variables/z-index';

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: $z-index--full-scren-modal;
  background-color: $color--main-bg;

  &__header,
  &__content {
    padding: $spacing--16 $spacing--24;
  }

  &__header {
    border-bottom: 1px solid $color--grey-4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $color--main-bg;
    z-index: $z-index--full-scren-modal__header;

    &__title {
      display: flex;
      flex-direction: column;

      & > span:not(:last-child) {
        margin-bottom: $spacing--4;
      }
    }
  }

  &__content {
    padding-top: $spacing--24;
    max-width: $container--width;
    margin: 0 auto;
  }
}