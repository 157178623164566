@import '~styles/variables/spacing';
  
.Tabs {

  .tabs-container {
    border-bottom: 1px solid var(--white-shade-4);
    margin-bottom: $spacing--24;

    .tab {
      padding: 1.429rem;
      display: inline-block;
      position: relative;
      font-size: 0.857rem;
      color: var(--grey-a50);
      font-weight: 700;
      text-transform: uppercase;
      transition: var(--ease-transition);

      &:hover {
        cursor: pointer;
        color: var(--grey);
      }

      &.active {
        color: var(--primary);

        &::after {
          transform: translateY(50%);
          opacity: 1;
          visibility: visible;
        }
      }

      &::after {
        content: "";
        background-color: var(--primary);
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        transform: translateY(150%);
        opacity: 0;
        visibility: hidden;
        transition: var(--ease-transition);
      }
    }
  }


  .tab-content {
    opacity: 0;
    visibility: hidden;
    display: none;

    &.active {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
}