@import "~styles/helpers/image";

@import "~styles/variables/border-radius";
@import "~styles/variables/colors";
@import "~styles/variables/spacing";
@import "~styles/variables/typography";

$image-input--height: 300px;

.image-input {
  $root: &;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: $image-input--height;
  

  &--has-image {
    position: relative;
  }

  &__label {
    text-transform: uppercase;
    // Not a constant since it's very specific and used
    // only for the label
    font-size: 11px;
    font-weight: $font-weight--bold;
    color: $color--grey-2;

    .icon {
      margin-right: $spacing--4;
    }
  }

  &__upload-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed $color--grey-3;
    border-radius: $border-radius--card;
    padding: $spacing--48;
    height: $image-input--height;

    #{$root}--has-image & {
      padding: 0;
      border-color: transparent;
    }

    #{$root}--has-label & {
      margin-top: $spacing--4;
    }
  }

  &__upload-image {
    margin-bottom: $spacing--16;
  }

  &__uploaded-image {
    @include coverImageToFitParent();
    border-radius: $border-radius--card;
    overflow: hidden;
    display: flex;
    height: $image-input--height;

    #{$root}--has-label & {
      margin-top: $spacing--4;
    }

    .inline-actions {
      position: absolute;
      bottom: $spacing--8;
      right: $spacing--8;
    }
  }

  .text {
    text-align: center;
  }
}