@import "~styles/variables/shadows";
@import "~styles/variables/spacing";

.timeline-sortable {
  margin-bottom: $spacing--8;
  
  &--is-dragging {
    box-shadow: $shadow--card;
  }

  &--is-disabled {
    cursor: no-drop;
  }
}