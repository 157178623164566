$alignment: 'flex-start', 'flex-end', 'center', 'strech';
$justification: 'flex-start', 'flex-end', 'center', 'space-between', 'space-around';
$directions: 'row', 'row-reverse', 'column', 'column-reverse';

.flex {
  display: flex;

  @each $align in $alignment {
    &--align-#{$align} {
      align-items: #{$align};
    }
  }

  @each $justify in $justification {
    &--justify-#{$justify} {
      justify-content: #{$justify};
    }
  }

  @each $dir in $directions {
    &--dir-#{$dir} {
      flex-direction: #{$dir};
    }
  }
}