@import '~styles/variables/border-radius';
@import '~styles/variables/colors';
@import '~styles/variables/shadows';
@import '~styles/variables/spacing';
@import '~styles/variables/typography';

.select-outer {
  display: inline-flex;
  flex: 1;
  flex-direction: column;

  &__label {
    text-transform: uppercase;
    // Not a constant since it's very specific and used
    // only for the label
    font-size: 11px;
    font-weight: $font-weight--bold;
    color: $color--grey-2;

    .icon {
      margin-right: $spacing--4;
      
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.select-container {

  &--has-label {
    margin-top: $spacing--4,
  }

  .select {
    &__control,
    &__option {
      &:hover {
        cursor: pointer;
      }
    }
  
    &__control {
      border-color: $color--grey-3;
      border-radius: $border-radius--card;
      padding: $spacing--6 $spacing--8;
      padding-right: 0;
      background-color: $color--grey-5;
  
      &--is-focused,
      &:hover {
        border-color: $color--primary;
        box-shadow: none;
      }

      &--is-disabled {
        background-color: $color--grey-4;
        color: $color--grey-2;
      }
    }
  
    &__input {
      color: $color--grey-1 !important;
    }

    &__indicator {
      color: $color--grey-2;
    }

    &__single-value {
      color: $color--grey-2;
    }
  
    &__menu {
      border-radius: $border-radius--card;
      box-shadow: $shadow--card;
    }
  
    &__menu-list {
      padding-bottom: 0;
      padding-top: 0;
    }

    &__menu-notice {
      padding: $spacing--16 $spacing--12;
    }
  
    &__option {
      padding: $spacing--12 $spacing--8;
      font-size: $font-size--sm;
      color: $color--grey-1;
  
      &--is-focused {
        background-color: transparentize($color--primary, 0.9);
      }
  
      &--is-selected,
      &:active {
        color: $color--white;
        background-color: $color--primary;
      }
  
      &:not(:last-child) {
        border-bottom: 1px solid $color--grey-4;
      }
  
      &:first-child {
        border-top-left-radius: $border-radius--card;
        border-top-right-radius: $border-radius--card;
      }
  
      &:last-child {
        border-bottom-left-radius: $border-radius--card;
        border-bottom-right-radius: $border-radius--card;
      }
    }
  }

  // Fullwidth
  &--fullwidth {
    width: 100%;
  }

  // Transparent
  &--is-transparent {
    .select {
      &__control {
        background-color: transparent;
        border-color: $color--grey-2;
      }
    }
  }

  // Compact
  &--is-compact {
    .select {
      &__control {
        background-color: transparent;
        border-color: transparent;
        padding-left: 0;

        &--is-disabled {
          background-color: transparent;
          opacity: 0.6;
        }

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  // Text Align - right
  &--text-align-right {
    .select {
      &__control,
      &__input,
      &__option {
        text-align: right;
      }

      // We always want the placeholder to be left aligned
      &__placeholder {
        text-align: left;
      }
    }
  }

  // Size - small
  &--size-small {
    .select {
      &__control {
        padding: 0;
      }

      &__single-value,
      &__placeholder {
        font-size: $font-size--sm;
      }
    }
  }

  // Size - small
  &--size-extra-small {
    .select {
      &__control {
        min-height: 32px;
        padding: 0;
      }

      &__single-value,
      &__placeholder {
        font-size: $font-size--sm;
      }

      &__dropdown-indicator,
      &__clear-indicator {
        padding: $spacing--4;
      }
    }
  }

  // Errors
  &--has-error {
    .select {
      &__control {
        border-color: $color--error;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &--is-focused,
        &:hover {
          border-color: $color--error;
          box-shadow: none;
        }
      }
    }
  }
}