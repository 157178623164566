.clickable
  &:hover
    cursor: pointer


.page-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 35px
  padding: 10px 20px
  margin-left: -20px
  margin-right: -20px
  position: sticky
  background-color: var(--main-bg)
  top: var(--actionbar-height)
  z-index: 999
  &.no-padding-top
    padding-top: 0

  .page-title
    margin-top: 0
    margin-bottom: 0

    

.page-fixed-footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  padding: 1.286rem 1.785rem
  background-color: var(--main-bg)
  z-index: 999
  display: flex
  justify-content: space-between
  align-items: center
  &.manager,
  &.admin
    left: var(--sidebar-width)

  .footer-item
    margin-left: 1.429rem
    &:first-child
      margin-left: 0



.section-divider
  height: 3px
  width: 160px
  background-color: var(--black-a10)
  display: block
  margin: 45px auto


.columns
  display: flex
  margin-bottom: 15px
  & > .flex-2
    flex: 2
  & > *
    flex: 1
    margin-right: 1.071rem
    &:last-child
      margin-right: 0


.grid
  display: grid
  grid-gap: 20px

  &.two-items
    grid-template-columns: repeat(2, 1fr)
  &.three-items
    grid-template-columns: repeat(3, 1fr)
  &.four-items
    grid-template-columns: repeat(4, 1fr)
  &.five-items
    grid-template-columns: repeat(5, 1fr)
  &.six-items
    grid-template-columns: repeat(6, 1fr)
