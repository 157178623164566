@import '~styles/variables/spacing';
@import '~styles/variables/z-index';

.page-layout {
  &__actionbar {
    position: sticky;
    top: 0;
    z-index: $z-index--action-bar;
  }

  &__content {
    padding: $spacing--32 $spacing--24;
  }
}