@import '~styles/constants/intent';

@import '~styles/helpers/colors';

@import '~styles/variables/border-radius';
@import '~styles/variables/spacing';


.status-tag {
  display: inline-flex;
  align-items: center;
  padding: #{$spacing--12 - 2px} $spacing--14;
  background-color: transparentize($color--grey-1, 0.9);
  border-radius: $border-radius--button;

  &--fullwidth {
    display: flex;
  }

  &__indicator {
    width: $spacing--12;
    height: $spacing--12;
    margin-right: $spacing--8;
    border-radius: #{$spacing--12 * 0.5};
    transform: translateY(0.5px);

    @each $intent in $intents {
      &--#{$intent} {
        background-color: getColorForIntent($intent);
      }
    }
  }
}