@import '~styles/variables/colors';
@import '~styles/variables/spacing';

.product-price-tooltip-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: $spacing--8;
  }
  
  &__label {
    color: $color--grey-2;
    
    & > .icon {
      margin-right: $spacing--6;
    }
  }
}