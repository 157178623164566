@import "~styles/variables/spacing";

.empty-purchase-orders-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__illustration {
    margin-top: $spacing--64;
    margin-bottom: $spacing--32;
  }

  &__heading {
    margin-bottom: $spacing--16;
  }

  &__text {
    max-width: 480px;
    text-align: center;
    line-height: 1.618;
    margin-bottom: $spacing--32;
  }

  .button {
    flex-shrink: 1;
    align-self: center;
  }
}