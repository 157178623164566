@import "~styles/variables/colors";
@import "~styles/variables/shadows";
@import "~styles/variables/spacing";
@import "~styles/variables/z-index";

.sidesheet-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: $z-index--sidesheet;
  display: flex;
  justify-content: flex-end;

  &__backdrop {
    z-index: $z-index--sidesheet__backdrop;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize($color--black, 0.8);
  }

  &__content {
    z-index: $z-index--sidesheet__content;
    background-color: $color--white;
    width: 640px;
    max-width: 75%;
    box-shadow: $shadow--card;
    position: relative;
    display: flex;
    flex-direction: column;

    &__header,
    &__body,
    &__footer {
      padding: $spacing--24;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color--grey-3;

      .icon {
        background-color: transparent;
        padding: $spacing--4;
        border-radius: 50%;
        transition: background-color 200ms ease-in-out;
        
        &:hover {
          background-color: transparentize($color--black, 0.93);
          cursor: pointer;
        }
      }
    }

    &__body {
      overflow-y: scroll;
      flex: 1;
      padding-bottom: #{$spacing--24 * 2 + 50px}
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: lighten($color--grey-5, 3%);
      border-top: 1px solid $color--grey-4;
    }
  }
}